@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Thin_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Thin_Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-ThinItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-ThinItalic_Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-XLight_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-XLight_Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-XLightItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-XLightItalic_Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Light_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Light_Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-LightItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-LightItalic_Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Book_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Book_Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-BookItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-BookItalic_Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Medium_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Medium_Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-MediumItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-MediumItalic_Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Bold_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Bold_Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-BoldItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-BoldItalic_Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Black_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Black_Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-BlackItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-BlackItalic_Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-Ultra_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-Ultra_Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src:
    url("/fonts/woff2/Gotham-UltraItalic_Web.woff2") format("woff2"),
    url("/fonts/woff/Gotham-UltraItalic_Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

:root {
  --toastify-color-info: #2a67f6;
  --toastify-color-success: #10d1bb;
  --toastify-color-warning: #f8ec5b;
  --toastify-color-error: #ed3f55;
}

* {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(#000, 0);
  font-family: "Gotham", sans-serif;
  @apply min-h-screen overflow-y-scroll text-base;
}

body {
  @apply m-0 bg-ultraLightBlue;
}

button:focus-visible {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2;
}

a {
  @apply text-xs font-medium text-accentBlue hover:underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply m-0;
}

.is-editor-empty:first-of-type::before {
  @apply text-gray-800 !important;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

@layer utilities {
  .transition-max-height {
    transition: max-height 0.3s ease-in-out;
  }
}
